<template>
  <div class="main home">
    <!-- 手机版的切换语言的按钮 -->
    <div v-if="this.GLOBAL.isMobile" style="position: absolute;z-index: 50;top:80px;right: 20px;height: 30px;
            background-color: #ffffff;font-size: 15px;text-align: center;display: inline-block;line-height: 30px;
            border-radius: 15px;padding-left: 5px;padding-right: 10px"
         @click="openLangDrawer">
      <img src="../assets/earth.svg" width="20px" style="display: inline-block;vertical-align: middle;margin-bottom: 3px">
      <span> {{ $t('lang') }} </span>
    </div>
    <el-drawer :title="$t('text9')" :visible.sync="showLang" size="40%" :modal="false" direction="btt">
      <div style="width: 100%;height: 100%;background-color: #f5f5f7;"><!--#f5f5f7-->
        <div class="drawer-item" @click="changeLang('CN')"> 中文</div>
        <div class="drawer-item" @click="changeLang('EN')"> English</div>
        <div class="drawer-item" @click="changeLang('RU')"> Русский язык</div>
      </div>
    </el-drawer>

    <!-- 轮播图-->
    <div class="carousel-map" :style="'margin-top:' + (this.GLOBAL.isMobile ? '60px':'0')">
      <el-carousel ref="carousel" :autoplay=false trigger="click" :height="this.GLOBAL.isMobile ? '60vw':'30vw'"
                   @change="handleCarouselChange">
        <el-carousel-item v-for="(item, index)  in bannerList" :key="index">
          <el-image v-if="item.type === 'PIC'" :ref="'carouselItem' + index"
                    style="width: 100%; height: 100%;"
                    :src="cp + '/jyy/billFile/showFile?uuid=' + item.uuid"
                    fit="cover"></el-image>
          <video v-if="item.type === 'VIDEO'" @ended="handleVideoEnded" :ref="'carouselItem' + index"
                 :src="cp + '/jyy/billFile/showFile?uuid=' + item.uuid"
                 muted width="100%" style="width: 100%; height: 100%;"></video>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="has-border-bottom py-3" v-if="nearestNews">
      <div class="container has-text-centered" style="font-size: 1.05rem">
        <span class="tag is-primary mr-3">{{ $t('index_21') }}</span>
        <span class="divider has-text-grey-light">|</span>
        <a class="mx-3 py-2 is-inline-block link-animate"
           :href="['#/news/detail?id=' + nearestNews.id]"
           name="&amp;lpos=apps_scodevmw : 34"
           onclick="s_objectID='apps_scodevmw : State of Spring Survey Report : 34'">
          {{ nearestNews.newsTitle }}
        </a>
      </div>
    </div>
    <div class="container py-6">
      <div class="features">
        <h1 class="is-size-2 mb-6 mt-4 has-text-weight-medium">{{ $t('index_22') }}</h1>
        <div class="columns is-centered is-multiline features visible">
          <article v-for="product in productList"
                   class="feature column is-half-tablet is-one-third-desktop is-one-quarter-widescreen">
            <a class="box is-special has-text-centered"
               :href="['#/productDetail?id=' + product.id]"
               name="&amp;lpos=apps_scodevmw : 36"
               onclick="s_objectID='apps_scodevmw : img/icons/reactive.svg : 36'"
            >
              <img class="icon" :src="cp + '/jyy/billFile/getFile?uuid=' + product.showPicUuid"
                   style="width: 200px;height: 200px"
              />
              <h1 class="is-size-4 has-text-weight-bold has-text-centered mb-4">
                {{ product.productName }} {{ product.productSpec }}
              </h1>
              <p class="has-text-centered">{{ product.showTitle }}</p>
            </a>
          </article>
        </div>
      </div>
      <div class="code mt-6 pt-6">
        <div class="columns" v-if="!this.GLOBAL.isMobile">
          <div class="column is-three-fifths pr-6">
            <!-- <img class="icon" src="../assets/map.png" style="width: 1000px; height: 350px" /> -->
            <div id="container"></div>
          </div>
          <div class="column is-two-fifths4">
            <div class="markdown links-animate content">
              <h2>{{ $t('index_23') }}</h2>
              <p style="font-size: 16px">{{ $t('index_24') }}</p>
              <p style="font-size: 16px">{{ $t('index_25') }}</p>
              <p style="font-size: 16px">{{ $t('index_26') }}</p>
              <p style="font-size: 16px">{{ $t('index_27') }}<a target="_blank" style="color: #0a0a0a;" href="https://www.amap.com/search?query=北京新飞达电子科技工业发展中心">{{ $t('index_28') }}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="columns" v-if="this.GLOBAL.isMobile">
          <div class="column is-three-fifths pr-6">
            <img alt="" src="../assets/map.png" style="width: 100%;"/>
          </div>
          <div class="column is-two-fifths4">
            <div class="markdown links-animate content">
              <h2>联系我们</h2>
              <p style="font-size: 16px">{{ $t('index_24') }}</p>
              <p style="font-size: 16px">{{ $t('index_25') }}</p>
              <p style="font-size: 16px">{{ $t('index_26') }}</p>
              <p style="font-size: 16px">{{ $t('index_27') }}{{ $t('index_28') }}（<a target="_blank" @click="openMapDrawer"> {{ $t('index_29') }} </a>）</p>
            </div>
            <el-drawer :title="$t('index_30')" :visible.sync="showSelectMap" size="40%" :modal="false" direction="btt">
              <div style="width: 100%;height: 100%;background-color: #f5f5f7;"><!--#f5f5f7-->
                <div class="drawer-item" @click="selectMap('GD')"> {{ $t('index_31') }}</div>
                <div class="drawer-item" @click="selectMap('TX')"> {{ $t('index_32') }}</div>
                <div class="drawer-item" @click="selectMap('BD')"> {{ $t('index_33') }}</div>
              </div>
            </el-drawer>
          </div>
        </div>
      </div>
      <div class="testimony my-6 py-6" style="max-width: 1120px;">
        <img class="testimony-quote mb-5" alt="Quote" width="70" src="../assets/quote.svg"/>
        <div class="testimony-text mb-5 is-size-4" style="text-align: justify;text-indent: 2em;">
          <p>{{ $t('index_34') }}</p>
          <p>{{ $t('index_35_1') }}</p>
          <p>{{ $t('index_35_1_1') }}</p>
          <p>{{ $t('index_35_2') }}</p>
          <p>{{ $t('index_35_2_1') }}</p>
          <p>{{ $t('index_35_3') }}</p>
          <p>{{ $t('index_35_3_1') }}</p>
          <p>{{ $t('index_35_4') }}</p>
          <p>{{ $t('index_35_4_1') }}</p>
          <p>{{ $t('index_35_99') }}</p>
        </div>
        <div>
          <img src="../assets/icon_hope_deer.png" width="220px"/>
        </div>
      </div>
    </div>
    <div class="default-banner has-background-light py-6">
      <div class="extra is-hidden-mobile is-hidden-tablet-only">
        <div class="extra-1"></div>
        <div class="extra-2"><img src="../assets/footer.svg" alt=""/></div>
      </div>
      <div class="content py-6 has-text-left">
        <div class="container">
          <div class="columns is-variable is-8">
            <article class="column">
              <h1 class="is-size-4 has-text-weight-bold mb-2">{{ $t('index_36') }}</h1>
              <p class="mb-1">
                {{ $t('index_37') }}
              </p>
              <a v-if="productTypeList" class="is-link link-animate" :href="'#/productList?type=' + productTypeList[0].id">{{ $t('index_42') }}</a>
            </article>
            <article class="column">
              <h1 class="is-size-4 has-text-weight-bold mb-2">{{ $t('index_38') }}</h1>
              <p class="mb-1">
                {{ $t('index_39') }}
              </p>
              <a class="is-link link-animate" href="#/companyInfo?type=RYZZ">{{ $t('index_42') }}</a>
            </article>
            <article class="column">
              <h1 class="is-size-4 has-text-weight-bold mb-2">{{ $t('index_7') }}</h1>
              <p class="mb-1">{{ $t('index_40') }}</p>
              <a v-if="newsTypeList && !this.GLOBAL.isMobile" class="is-link link-animate" :href="'#/news?type=' + newsTypeList[0].id">{{ $t('index_41') }}</a>
              <a v-if="this.GLOBAL.isMobile" class="is-link link-animate" href="#/news">{{ $t('index_41') }}</a>
            </article>
          </div>
        </div>
      </div>
      <div v-if="this.GLOBAL.isMobile" style="height: 20px"></div>
    </div>
  </div>
</template>
<script>
import axios from '@/utils/axios'

export default {
  data() {
    return {
      cp: this.GLOBAL.cp,
      nearestNews: null,
      productList: [],
      newsTypeList: null,
      productTypeList: null,
      bannerList: [], // 轮播图地址
      bannerIndex: 0, // 当前轮播图的索引
      showSelectMap: false,
      showLang: false,
    }
  },

  methods: {
    initMap() {
      if (!this.GLOBAL.isMobile) {
        var center = new TMap.LatLng(40.148901, 116.281965) //设置中心点坐标
        //初始化地图
        var map = new TMap.Map('container', {
          center: center,
        })
        //初始化infoWindow
        var infoWindow = new TMap.InfoWindow({
          map: map,
          position: center, //设置信息框位置
          content: '北京新飞达电子科技工业发展中心', //设置信息框内容
        })
      }
    },
    // 初始化轮播图
    initBanner() {
      console.log(this.bannerIndex)
      let thisBanner = this.bannerList[this.bannerIndex];
      if (thisBanner.type === 'VIDEO') {
        this.$nextTick(() => {
          const videoElement = this.$refs['carouselItem' + this.bannerIndex];
          if (videoElement) {
            videoElement[0].play();
          }
        });
      } else if (thisBanner.type === 'PIC') {
        this.timer = setTimeout(() => {
          this.$refs.carousel.next();
        }, 5000); // 等待5秒后切换到下一张幻灯片
      }
    },
    // 切换幻灯片时更新当前索引并清除计时器
    handleCarouselChange(index, oldIndex) {
      console.log("切换幻灯片为：" + index, ",原来为：" + oldIndex)
      // 先将原来的定时器和视频暂停或清除
      clearTimeout(this.timer);
      const oldItem = this.bannerList[oldIndex];
      if (oldItem.type === 'VIDEO') {
        const videoElement = this.$refs['carouselItem' + oldIndex];
        if (videoElement) {
          videoElement[0].pause();
        }
      }
      // 再开始处理新的逻辑
      this.bannerIndex = index;
      let thisBanner = this.bannerList[this.bannerIndex];
      if (thisBanner.type === 'VIDEO') {
        this.$nextTick(() => {
          const videoElement = this.$refs['carouselItem' + this.bannerIndex];
          if (videoElement) {
            videoElement[0].currentTime = 0; // 重置视频播放时间
            videoElement[0].play();
          }
        });
      } else if (thisBanner.type === 'PIC') {
        this.timer = setTimeout(() => {
          this.$refs.carousel.next();
          this.initBanner();
        }, 5000); // 等待5秒后切换到下一张幻灯片
      }
    },
    // 视频播放结束
    handleVideoEnded() {
      console.log("视频播放结束")
      // 视频结束后切换到下一张幻灯片
      this.$refs.carousel.next();
    },
    // 选择地图
    openMapDrawer() {
      this.showSelectMap = true
    },
    selectMap(val) {
      this.showSelectMap = false
      if ("BD" === val) {
        // window.open(`http://api.map.baidu.com/direction?destination=latlng:116.288275,40.155292|name:北京新飞达电子科技工业发展中心&mode=driving&output=html&src=webapp.baidu.openAPIdemo&coord_type=bd09ll`);
        window.open(`http://api.map.baidu.com/marker?location=40.1475,116.2760&title=北京新飞达电子科技工业发展中心&content=北京新飞达电子科技工业发展中心&output=html&coord_type=wgs84`);
      } else if ("GD" === val) {
        window.open(`https://uri.amap.com/navigation?to=116.28,40.15,北京新飞达电子科技工业发展中心&mode=car&callnative=1&coordinate=wgs84`);
      } else if ("TX" === val) {
        window.open(`http://apis.map.qq.com/uri/v1/marker?marker=coord:40.148901,116.281965;addr:北京新飞达电子科技工业发展中心`);
      }
    },
    // 选择地图
    openLangDrawer() {
      this.showLang = true
    },
    changeLang(lang) {
      this.showLang = false
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang)
      location.reload();
    }
  },

  mounted() {
    axios.get(this.cp + '/jyy/portal/getPortalCarouselList')
      .then(res => {
        this.bannerList = res.data.data
        this.initBanner()
      })
    axios.get(this.cp + '/jyy/portal/getProductShowList')
      .then(res => {
        this.productList = res.data.data
      })
    axios.get(this.cp + '/jyy/portal/selectNearestNews')
      .then(res => {
        this.nearestNews = res.data.data
      })
    axios.get(this.cp + '/jyy/portal/getNewsType')
      .then(res => {
        this.newsTypeList = res.data
      })
    axios.get(this.cp + '/jyy/portal/getProductTypeList')
      .then(res => {
        this.productTypeList = res.data
      })
    this.initMap()
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  }
}
</script>

<style lang="less" scoped>
// 轮播图

.carousel-map {
  width: 100%;
  height: 30vw;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  .carousel-map {
    height: 60vw;
  }
}






.drawer-item {
  font-size: 16px;
  text-align: center;
  padding: 15px;
  border: 1px solid #f5f5f7;
  background-color: #ffffff
}


</style>
